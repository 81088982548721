import React, { useState } from 'react';
import CryptoJS from 'crypto-js';
import "./App.css"

function App() {
  const [passphrase, setPassphrase] = useState("A1K56H7FLOH648HJK096KGS64GHFH975LOIUYUR42D5G");
  const [textToEncrypt, setTextToEncrypt] = useState('');
  const [textToDecrypt, setTextToDecrypt] = useState('');
  const [encrypted, setEncrypted] = useState('');
  const [decrypted, setDecrypted] = useState('');

  const aesEncrypt = (text, passphrase) => {
    var iv = CryptoJS.enc.Hex.parse('000102030405060708090a0b0c0d0e0f'); // Use the same IV as in PHP
    var salt = CryptoJS.enc.Hex.parse('000102030405060708090a0b0c0d0e0f'); // Use the same salt as in PHP
    var key = CryptoJS.PBKDF2(passphrase, salt, { keySize: 256 / 32, iterations: 1000 });

    var encryptedText = CryptoJS.AES.encrypt(text, key, { iv: iv, padding: CryptoJS.pad.Pkcs7 });
    return encryptedText.toString();
  }

  const aesDecrypt = (text, passphrase) => {
    var iv = CryptoJS.enc.Hex.parse('000102030405060708090a0b0c0d0e0f'); // Use the same IV as in PHP
    var salt = CryptoJS.enc.Hex.parse('000102030405060708090a0b0c0d0e0f'); // Use the same salt as in PHP
    var key = CryptoJS.PBKDF2(passphrase, salt, { keySize: 256 / 32, iterations: 1000 });

    var decryptedText = CryptoJS.AES.decrypt(text, key, { iv: iv, padding: CryptoJS.pad.Pkcs7 });
    return decryptedText.toString(CryptoJS.enc.Utf8);
  }

  const handleEncrypt = () => {
    const encryptedText = aesEncrypt(textToEncrypt, passphrase);
    setEncrypted(encryptedText);
  }

  const handleDecrypt = () => {
    const decryptedText = aesDecrypt(textToDecrypt, passphrase);
    setDecrypted(decryptedText);
  }

  return (
    <div className="App">
      <header className="App-header">
        {/* <div>
          <input type="text" value={passphrase} onChange={(e) => setPassphrase(e.target.value)} />
        </div> */}
        <div>
          <h2>Encryption</h2>
          <div>
            <label>Text to Encrypt: </label>
            <textarea value={textToEncrypt} onChange={(e) => setTextToEncrypt(e.target.value)} />
          </div>
          <div>
            <button onClick={handleEncrypt}>Encrypt</button>
          </div>
          <div>
            <label>Encrypted Text: </label>
            <textarea value={encrypted} />
          </div>
        </div>
        <div>
          <h2>Decryption</h2>
          <div>
            <label>Text to Decrypt: </label>
            <textarea value={textToDecrypt} onChange={(e) => setTextToDecrypt(e.target.value)} />
          </div>
          <div>
            <button onClick={handleDecrypt}>Decrypt</button>
          </div>
          <div>
            <label>Decrypted Text: </label>
            <textarea value={decrypted} />
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
